import { IconType } from 'react-icons';
import { Edge, Node, XYPosition } from 'reactflow';

import {
  Connection,
  ConnectionType,
  ManifestError,
  MarketplaceComponent,
  NodeDisplay,
  NodeType,
  ParameterType,
  Variable,
} from './api';

export type Leaf = {
  id: string;
  label: string;
  nodeType?: NodeType;
  type?: string;
};

export type Tree = Leaf & {
  data: Tree[] | Leaf[];
};

export type PreviousGraphVersionParamsType = {
  previousGraphVersion?: string | null;
};

export type OrgCreationParamsType = {
  create_org_success?: boolean | null;
  create_org_canceled?: boolean | null;
  session_id?: boolean | null;
};

export type OrgJoinParamsType = {
  joinedOrg?: boolean | null;
  new_org?: string | null;
};

export type GraphViewParamsType = {
  node?: string | null;
  topTab?: string | null;
  bottomTab?: string | null;
};

export type AuthParamsType = {
  code?: string | null;
  error?: string | null;
  state?: string | null;
  returnTo?: string | null;
};

export type DashboardParamsType = {
  dashboardId?: string | null;
};

export type QueryParams = OrgCreationParamsType &
  OrgJoinParamsType &
  AuthParamsType &
  PreviousGraphVersionParamsType &
  DashboardParamsType &
  GraphViewParamsType;

export type EdgePath = {
  port: string;
  node_id: string;
};

export enum PortType {
  Stream = 'stream',
  Table = 'table',
}

export type Port = {
  name: string;
  port_type: PortType;
  description?: string | null;
  schema_name?: string | null;
  required?: boolean;
  line_number?: number;
};

export type ParameterBase = {
  name: string;
  value?: any;
  default?: any;
  required?: boolean;
  description?: string;
  line_number?: number;
};

export type OtherParameter = ParameterBase & {
  parameter_type:
    | ParameterType.Text
    | ParameterType.Boolean
    | ParameterType.Integer
    | ParameterType.Float
    | ParameterType.Date
    | ParameterType.DateTime
    | ParameterType.List
    | ParameterType.Schema;
};

export type ConnectionParameter = ParameterBase & {
  parameter_type: ParameterType.Connection;
  connection_type: string;
};

export type Parameter = ConnectionParameter | OtherParameter;

export type UnusedParameter = {
  name: string;
  value: any;
};

export type NodeRunStatus = 'running' | 'completed' | 'failed';
export type LocalEdge = Edge<{
  sourcePort?: string;
}>;
export type NodeData = {
  id: string;
  childNodeErrors: ManifestError[] | null;
  display: NodeDisplay | null;
  description_path?: string | null;
  icon_url?: string | null;
  expanded: boolean;
  filePath?: string | null;
  fromComponent: string | null;
  inputs: Port[];
  isParent: boolean;
  local_input_edges: Edge[];
  local_output_edges: Edge[];
  manifestErrors: ManifestError[] | null;
  name: string;
  outputs: Port[];
  parameters: Parameter[];
  parentGraphLevel: string | null;
  parentNodeId: string | null;
  resolved_edges: LocalEdge[];
  resolvedParameters: Parameter[];
  resolvedStorage?: {
    definition: string;
    engines?: string[];
    namespacePath: string[];
  };
  runDuration?: number;
  runStatus?: NodeRunStatus;
  storageName?: string;
  trigger: string | null;
  type: NodeType;
  unused_parameter_values: UnusedParameter[];
  fileContents?: string;
  graphUID?: string;
  wait_for_response?: boolean;
};

export type PatternsNodeProps = {
  data: NodeData;
  id: string;
  selected: boolean;
  type: string;
  isConnectable: boolean;
  xPos: number;
  yPos: number;
};

export type NodeChanges = {
  [id: string]: { position_x?: number; position_y?: number; width?: number; height?: number };
};

export type HiddenDatabaseModalProps = {
  action: 'hidden';
};

export type LinkDatabaseModalProps = {
  action: 'create';
};

export type EditDatabaseModalProps = {
  action: 'edit';
  storageUid: string;
  error?: string;
};

export type DatabaseModalProps =
  | HiddenDatabaseModalProps
  | LinkDatabaseModalProps
  | EditDatabaseModalProps;

export type SecretsModalProps = {
  show: boolean;
  name?: string;
  description?: string;
  value?: string;
  isSecret?: boolean;
  isEditMode?: boolean;
  onSuccess?: (secret: Variable) => void;
};

export type CreateAppModalProps = {
  orgUID: string;
  graphUID?: string | null;
  graphVersionUID?: string | null;
  title: string | null;
  description?: string | null;
  componentUID?: string | null;
  theme?: VegaTheme;
};

export type views = 'graph' | 'dashboard';
export type ConnectionsModalProps = {
  show: boolean;
  connectingTo?: {
    connectionType: ConnectionType;
    failure?: string;
  };
  connectionForm?: ConnectionType;
  onSuccess?: (connectionResult?: Connection) => void;
};

export type ConnectionActions =
  | 'delete'
  | 'edit'
  | 'reconnect'
  | 'add_component'
  | 'view_components';

export type ConnectionModalProps = {
  connection?: Connection;
  action?: ConnectionActions;
  error?: string;
};

export type MarketplaceState = {
  search?: string | null;
  category?: string | null;
  itemUID?: string | null;
  itemType?: string | null;
};

export type AppState = {
  organizationUID: string | null;
  secretsModalProps: SecretsModalProps;
  connectionsModalProps: ConnectionsModalProps;
  connectionModalProps: ConnectionModalProps;
  databaseModalProps: DatabaseModalProps;
  showCreateOrgModal: boolean;
  showCreateAppModal: null | CreateAppModalProps;
  secretsToDelete: string[];
  view: views;

  setOrganizationUID: (organizationUID: string | null) => void;
  setSecretsModalProps: (secretModalProps: SecretsModalProps) => void;
  setConnectionsModalProps: (connectionsModalProps: ConnectionsModalProps) => void;
  setConnectionModalProps: (connectionModalProps: ConnectionModalProps) => void;
  setDatabaseModalProps: (databaseModalProps: DatabaseModalProps) => void;
  setShowCreateOrgModal: (showCreateOrgModal: boolean) => void;
  setShowCreateAppModal: (props: CreateAppModalProps | null) => void;
  setView: (view: views) => void;
  setSecretsToDelete: (secretsToDelete: string[]) => void;
};

export type NewNodeItem = GraphTemplate | MarketplaceComponent;

export type EditorWindowTab = {
  name: string; // either nodeid or the file path
  topTab?: NodeTab | null;
  bottomTab?: NodeTab | null;
  isReadme?: boolean;
};

export type EditorWindow = {
  id: string;
  show: boolean;
  isSidebar: boolean;
  activeTab: string;
  tabs: EditorWindowTab[];
  zIndex: number;
};

export type Store = Pick<NodeData, 'type' | 'name'>;

export { type GraphState } from 'views/Graph/state';

export type NodeError = {
  nodeId: string;
  nodeName: string;
  error: string;
};

export type GraphLookup = Record<
  string,
  { nodes: GraphNode[]; edges: Edge[]; manifestErrors: ManifestError[] }
>;

export type GraphNode = Node<NodeData>;

export type SidebarMode =
  | 'databases'
  | 'files'
  | 'versions'
  | 'connections'
  | 'secrets'
  | 'dashboards';

export type GraphTemplate = {
  templateType: string;
  name: string;
  description: string;
  icon?: IconType;
  icon_url?: string;
  fileContent: string;
  nodeNamePrefix: string;
  parent_id?: string | null;
  shortcut: string;
  title?: string;
  chart_input?: string;
  fileName?: string;
  wait_for_response?: boolean;
};

export type AddNodeData = {
  description?: string;
  iconUrl?: string;
  fileContents: string;
  nodeType: 'python' | 'sql' | 'markdown' | 'chart';
  title?: string;
  parentId?: string | null;
  position: XYPosition;
  addOutputStores?: boolean;
  chart_input?: string;
  fileName?: string;
};

export type AddChartNodeData = {
  fileContents: string;
  parentId?: string | null;
  position: XYPosition;
};

export type AddStoreNodeData = {
  templateType: string;
  parentId?: string | null;
  position: XYPosition;
  uniqueNodeName?: string;
  // function node to connect to
  nodeId?: string;
  nodePort?: string;
  direction?: 'input' | 'output';
};

export type AddWebhookData = {
  parent_id?: string | null;
  webhook: string;
  position_x: number;
  position_y: number;
  icon_url?: string | null;
  description?: string | null;
  addOutputStores?: boolean;
  wait_for_response?: boolean;
};

export enum NodeStatus {
  Error = 'error',
  None = 'none',
  Queued = 'queued',
  Running = 'running',
  Selected = 'selected',
  Success = 'success',
  WillRun = 'willRun',
  Killed = 'killed',
  Cancelled = 'cancelled',
}

export type NodeTab =
  | 'Code'
  | 'Data'
  | 'Console'
  | 'Schema'
  | 'Settings'
  | 'Templates'
  | 'Readme'
  | 'Preview'
  | 'Input'
  | 'Output'
  | 'Executions'
  | 'Test';

export type IdeState = {
  [id: string]: {
    lineNumber?: number;
  };
};

export type VegaTheme =
  | 'default'
  | 'dark'
  | 'excel'
  | 'fivethirtyeight'
  | 'ggplot2'
  | 'latimes'
  | 'quartz'
  | 'vox'
  | 'urbaninstitute'
  | 'googlecharts'
  | 'powerbi';

export type ConnectingHandle = { nodeData: NodeData; type: 'source' | 'target' } | null;

export type AccumulatorNode = {
  key: string;
  type: 'connection' | 'node';
  count: number;
  connection?: string;
  iconUrl?: string;
  positions: number[];
};

export type NodeAccumulator = AccumulatorNode[];
